/* LandingPage.css */
body {
    background-color: #ffffff; /* Pure white background for a clean look */
    color: #333; /* Darker text for better readability */
    font-family: 'Helvetica Neue', sans-serif; /* Modern, clean font choice */
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.navbar {
    background-color: #4a69bd; /* Deep blue for navbar */
    padding: 10px 20px;
    display: flex;
    justify-content: flex-end;
}

.navbar a {
    color: #f8c291; /* Soft orange for links */
    font-family: 'Arial', sans-serif;
    text-decoration: none;
    margin-left: 15px;
    font-size: 16px;
    transition: color 0.3s ease-in-out;
}

.navbar a:hover {
    color: #f6b93b; /* Brighter orange on hover */
}

.hero {
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background: linear-gradient(135deg, #60a3bc 0%, #82ccdd 100%); /* Smooth blue gradient */
    color: #fff;
    padding: 50px 20px;
}

.hero h1 {
    font-size: 2.5em;
}

.hero p {
    font-size: 1.2em;
    animation: fadeIn 2s ease-in-out;
}

.features, .testimonials, .faq {
    text-align: center;
    padding: 40px 20px;
    animation: fadeInUp 1s ease-in-out;
}

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.faq-item {
    margin-top: 20px;
    cursor: pointer;
    padding: 10px;
    border: 1px solid #82ccdd; /* Light border using a soft blue */
    transition: background-color 0.3s ease;
}

.faq-item:hover {
    background-color: #b8e994; /* Light green background on hover */
}
